/**
 * @Author: Fernando Sanchez
 */
// -- React Stuff -- //
import React, { useState } from "react";
// -- Microsoft Stuff
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
// -- Material UI Components & Stuff -- //
import { styled } from "@mui/system";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Fade,
    Grid,
    Link,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
// -- Assets
import logo from "../../images/logotipo.svg";
import microsoft from "../../images/microsoft.svg";
import background from "../../assets/images/app_background.jpg";
// -- Config
import { loginRequest } from "../../auth/authConfig";

function LoginMicrosoft(props) {

    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState(null);
    var [loginValue, setLoginValue] = useState("");
    var [passwordValue, setPasswordValue] = useState("");

    // microsoft
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    // heart
    var [ isHovered, setIsHovered ] = useState(false);

    const handleLoginImp = async (accounts) => {
        setIsLoading(true);
        accounts = instance.getAllAccounts();

        if(accounts.length > 0){
            instance.setActiveAccount(accounts[0]);
        } else {
            try {
                 await instance.loginRedirect();
            }catch (error) {
                console.error("Login Failed: ", error);
            }
            /*

            instance.addEventCallback((event) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                    const account = event.payload.account;
                    instance.setActiveAccount(account);
                }
            }, error => {
                console.log('errorP: ', error);
            });

            instance.handleRedirectPromise().then(async authResult => {
                const account = instance.getActiveAccount();
                if (!account) {
                    await instance.loginRedirect();
                }
            }).catch(error => {
                console.log(error);
            });
            RequestAccessToken();*/
        }
        setIsLoading(false);
    }

    function RequestAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.accessToken);
        }).catch((e) => {
            instance.acquireTokenRedirect(request).then((response) => {
                setAccessToken(response.accessToken);
            });
        });
    }

    const MyGrid = styled(Grid)({
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
    });

    // bacjground color could be random
    const DivLogotypeContainer = styled('div')(({ theme }) => ({
        backgroundColor: "#707070",//theme.palette.error.dark,
        background: `url(${background}) no-repeat`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: "60%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    }));

    const LogoTypeImg = styled('img')(({ theme }) => ({
        width: 420,
        marginBottom: theme.spacing(4),
    }));

    const LogoTypeText = styled(Typography)(({ theme }) => ({
        color: "white",
        fontWeight: 500,
        fontSize: 52,
        [theme.breakpoints.down("md")]: {
            fontSize: 48,
        },
    }));

    const FormContainer = styled('div')(({ theme }) => ({
        width: "40%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: '10vh',
        [theme.breakpoints.down("md")]: {
            width: "100%",
            paddingTop: 0
        },
    }));

    const Form = styled('div')(({ theme }) => ({
        width: 320,
    }));

    const Greeting = styled(Typography)(({ theme }) => ({
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(4),
    }));

    const MicrosoftButton = styled(Button)(({ theme }) => ({
        marginTop: theme.spacing(6),
        boxShadow: theme.shadows[2],
        backgroundColor: "white",
        width: "100%",
        textTransform: "none",
    }));

    const MicrosoftIcon = styled('img')(({ theme }) => ({
        width: 30,
        marginRight: theme.spacing(2),
    }));

    const FormDividerContainer = styled('div')(({ theme }) => ({
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: "flex",
        alignItems: "center",
    }));

    const FormDivider = styled('div')(({ theme }) => ({
        flexGrow: 1,
        height: 1,
        backgroundColor: theme.palette.text.hint + "40",
    }));

    const toggleHover = () => {
        setIsHovered(!isHovered);
        console.log("hey");
    }

    return (
        <MyGrid container>
            <DivLogotypeContainer />
            <FormContainer>
                <Form sx={{minHeight: `80vh`}} >
                    <React.Fragment>
                        <Stack textAlign={'center'} >
                            <Box
                                sx={{
                                    height: '100px',
                                    alignContent: 'center',
                                    marginTop: '20px'
                                }}
                            >
                                <Link href="https://padilla-bujalil.com.mx" underline="none" target="_blank">
                                    <img
                                        src={logo} alt={'logo'}
                                    />
                                </Link>
                            </Box>
                        </Stack>
                        <Box>
                            <Divider sx={{ color: 'gray' }}></Divider>
                        </Box>
                        <Greeting variant="h1">
                            Bienvenido!
                        </Greeting>
                        <Stack textAlign={'center'}>
                            {
                                isLoading ?
                                    (
                                        <Box sx={{marginTop: '20px'}}>
                                            <CircularProgress size={50} />
                                            <Typography variant={'h6'}>Cargando...</Typography>
                                            <Typography>Por favor sigue las instrucciones de Microsoft</Typography>
                                        </Box>
                                    ):
                                    (
                                        <MicrosoftButton
                                            size="large"
                                            onClick={() => {handleLoginImp(accounts).then(r => console.log(r))}}>
                                            <MicrosoftIcon src={microsoft} alt="microsoft logo"></MicrosoftIcon>
                                            &nbsp;Continuar con Microsoft
                                        </MicrosoftButton>
                                    )
                            }
                        </Stack>
                        <FormDividerContainer>
                            <FormDivider/>
                        </FormDividerContainer>
                        <Fade in={error}>
                            <Typography color="secondary" sx={{ textAlign: "center" }}>
                                Error, Usuario o Contraseña Incorrectos :(
                            </Typography>
                        </Fade>
                        { 1===1?<></>:
                        <>
                            <TextField
                                id="email"
                                value={loginValue}
                                onChange={e => setLoginValue(e.target.value)}
                                margin="normal"
                                placeholder="Correo"
                                type="email"
                                fullWidth />
                            <TextField
                                id="password"
                                value={passwordValue}
                                onChange={ e => setPasswordValue(e.target.value)}
                                margin={"normal"}
                                placeholder={"Contraseña"}
                                type={"password"}
                                fullWidth />
                            <div >
                                {isLoading ? (
                                    <CircularProgress size={26} />
                                ) : (
                                    <Button
                                        disabled={
                                            loginValue.length === 0 || passwordValue.length === 0
                                        }
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        Iniciar
                                    </Button>
                                )}
                                <Button
                                    color="primary"
                                    size="large"
                                >
                                    Olvidé la contraseña
                                </Button>
                            </div>
                        </> }
                    </React.Fragment>
                </Form>
                <Stack textAlign='center' sx={{maxHeight: `20vh`}}>
                    <Typography>
                        Created with ❤️ by <Link href='https://anti-ia.org/f' target='_blank'>
                        Fernando Sanchez
                    </Link>
                    </Typography>
                </Stack>
            </FormContainer>
        </MyGrid>
    );
}

export default LoginMicrosoft;