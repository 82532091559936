/**
 * @Author: Fernando Sanchez
 */
// -- React Stuff -- //
import React from "react";
import { useSelector } from "react-redux";
// -- Material UI Stuff
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
// -- MS Azure Auth Stuff -- //
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";


// -- Custom -- //
import LoginMicrosoft from "./pages/LoginMicrosoft/LoginMicrosoft";
import NavigationScroll from "./NewLayout/NavigationScroll";
import themes from "./themes";
// -- Routing -- //
import Routes from './routes';
// -- Styles Stuff -- //
import './App.css';

export default function App() {
    // global
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <AuthenticatedTemplate>
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <LoginMicrosoft/>
                </UnauthenticatedTemplate>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
