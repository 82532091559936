// assets
import {
    LibraryBooks,
    Groups, Groups3,
    Gavel, HomeWork,
    HolidayVillage, ContactPage,
    Hail, ImportContacts,
    CleaningServices, AssignmentInd,
    Badge, Feed,
    LowPriority, Receipt, Paid,
    DateRange, Difference, Payments,
    FiveMp
} from "@mui/icons-material";

// constant
const icons = {
    LibraryBooks, Groups,
    Groups3, Gavel, HomeWork,
    HolidayVillage, Hail, ContactPage,
    ImportContacts, CleaningServices,
    AssignmentInd, Badge,
    Feed, LowPriority, Receipt, Paid,
    DateRange, Difference, Payments,
    FiveMp
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const catalogos = {
    id: 'catalogos',
    type: 'group',
    children: [
        {
            id: 'authentication',
            title: 'Catálogos',
            type: 'collapse',
            icon: icons.LibraryBooks,

            children: [
                {
                    id: 'inmuebles',
                    title: 'Inmuebles',
                    type: 'item',
                    icon: icons.HomeWork,
                    url: '/catalogos/inmuebles',
                    target: false
                },
                {
                    id: 'inquilinos',
                    title: 'Inquilinos',
                    type: 'item',
                    icon: icons.Hail,
                    url: '/catalogos/inquilinos',
                    target: false
                },
                {
                    id: 'fiadores',
                    title: 'Obligados Solidarios',
                    type: 'item',
                    icon: icons.ContactPage,
                    url: '/catalogos/fiadores',
                    target: false
                },
                {
                    id: 'directorio',
                    title: 'Directorio',
                    type: 'item',
                    icon: icons.ImportContacts,
                    url: '/catalogos/directorio',
                    target: false
                },
                {
                    id: 'tiposInmueble',
                    title: 'Tipos de Inmueble',
                    type: 'item',
                    icon: icons.HolidayVillage,
                    url: '/catalogos/tiposInmueble',
                    target: false
                },
                {
                    id: 'tiposServicio',
                    title: 'Tipos de Servicio',
                    type: 'item',
                    icon: icons.CleaningServices,
                    url: '/catalogos/tiposServicio',
                    target: false
                },
                {
                    id: 'tiposPersonal',
                    title: 'Tipos de Personal',
                    type: 'item',
                    icon: icons.AssignmentInd,
                    url: '/catalogos/tiposPersonal',
                    target: false
                },
                {
                    id: 'personal',
                    title: 'Personal',
                    type: 'item',
                    icon: icons.Badge,
                    url: '/catalogos/personal',
                    target: false
                },
                {
                    id: 'tiposFormato',
                    title: 'Tipos de Formato',
                    type: 'item',
                    icon: icons.Feed,
                    url: '/catalogos/tiposFormato',
                    target: false
                },
                {
                    id: 'register3',
                    title: 'Dueños',
                    type: 'item',
                    icon: icons.Groups3,
                    url: '/catalogos/duenios',
                    target: false
                },
                {
                    id: 'prioridad',
                    title: 'Prioridad',
                    type: 'item',
                    icon: icons.LowPriority,
                    url: '/catalogos/prioridad',
                    target: false
                },
                {
                    id: 'tiposCargo',
                    title: 'Tipos de Cargo',
                    type: 'item',
                    icon: icons.Receipt,
                    url: '/catalogos/tiposCargo',
                    target: false
                },
                {
                    id: 'tiposCobro',
                    title: 'Tipos de Cobro',
                    type: 'item',
                    icon: icons.Paid,
                    url: '/catalogos/tiposCobro',
                    target: false
                },
                {
                    id: 'margen',
                    title: 'Margen',
                    type: 'item',
                    icon: icons.DateRange,
                    url: '/catalogos/margen',
                    target: false
                },
                {
                    id: 'periodo',
                    title: 'Periodo',
                    type: 'item',
                    icon: icons.DateRange,
                    url: '/catalogos/periodo',
                    target: false
                },
                {
                    id: 'folios',
                    title: 'Folios',
                    type: 'item',
                    icon: icons.Difference,
                    url: '/catalogos/folios',
                    target: false
                },
                {
                    id: 'metodosPago',
                    title: 'Métodos de Pago',
                    type: 'item',
                    icon: icons.Payments,
                    url: '/catalogos/metodosPago',
                    target: false
                },
                {
                    id: 'foliosCBB',
                    title: 'Folios CBB',
                    type: 'item',
                    icon: icons.FiveMp,
                    url: '/catalogos/foliosCBB',
                    target: false
                },
                {
                    id: 'login3',
                    title: 'Asesores',
                    type: 'item',
                    icon: icons.Groups,
                    url: '/catalogos/asesores',
                    target: false
                },
                {
                    id: 'apoderados',
                    title: 'Apoderados',
                    type: 'item',
                    icon: icons.Gavel,
                    url: '/catalogos/apoderados',
                    target: false
                }
            ]
        }
    ]
};

export default catalogos;
