/**
 * @Author: Fernando Sanchez
 */
// -- React Stuff -- //
import React from 'react';
import { Provider } from "react-redux";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
// -- Microsoft Azure Auth -- //
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
// -- Styles Stuff -- //
import './index.css';
// -- Main Content -- //
import App from './App';
// -- Other Libraries -- //
//import reportWebVitals from './reportWebVitals';
// -- Settings -- //
import { msalConfig } from "./auth/authConfig";
import { store } from "./store";
import config from "./config";

// Start MSAL Instance
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
      <MsalProvider instance={msalInstance}>
          <BrowserRouter basename={config.basename}>
              <App />
          </BrowserRouter>
      </MsalProvider>
  </Provider>
   /* <LayoutProvider>
        <MsalProvider instance={msalInstance}>
            <UserProvider>
                <ThemeProvider theme={Themes.default}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </UserProvider>
        </MsalProvider>
    </LayoutProvider>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//reportWebVitals();
