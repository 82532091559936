import dashboard from './dashboard';
import inmuebles from "./inmuebles";
import catalogos from './catalogos';
//import utilities from './utilities';
import administracion from "./administracion";
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    //items: [dashboard, pages, utilities, other]
    items: [dashboard, inmuebles, catalogos, administracion, other]
};

export default menuItems;
